html {
    font-size:16px;
}

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/* code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
} */

p {
    margin: 0;
}