@font-face {
    font-family: "Kang Xi Zi Dian";
    font-style: normal;
    font-weight: 400;
    src: url("../font/KangXiDictFull.subset.otf") format("opentype");
    font-display: swap;
}

@font-face {
    font-family: "Zi Yue Song Ke Ben";
    font-style: normal;
    font-weight: 400;
    src: url("../font/ziyuesongkeben.subset.ttf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "Adobe Kaiti Std";
    font-style: normal;
    font-weight: 400;
    src: local("Adobe Kaiti Std"), url("../font/adobekaitistd.subset.otf") format("opentype");
}

@font-face {
    font-family: "Arial Unicode MS Subset";
    font-style: normal;
    font-weight: 400;
    src: url("../font/arial-unicode-ms.subset.ttf") format("truetype");
    font-display: swap;
}


