.App {
    text-align: center;
    font-family: "Zi Yue Song Ke Ben", "Adobe Kaiti Std", "KaiTi_GB2312", "KaiTi", "STKaiti", "SimSun", "STFangsong", "STSong";
}

.AppBody {
    /* background-color: #282c34; */
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* font-size: calc(10px + 2vmin); */
    /* color: white; */
}
